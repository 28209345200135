import vue from "vue";
import store from "../store";
// import i18n from "../i18n";
import { isDefined, tokenIsValid, } from "../utils";
import { canAccessProject, canAccessBilling } from "../utils/permissions";
import SendInvitation from "../components/modale/sendInvitation";
// import { SET_DISCONNECTED } from "@/store/mutations-types";
// import { dropVueStorage } from "../utils";

function isResettingPassword(to) {
  return ["ResetPasswordVerifToken", "ResetPasswordTokenValid"].includes(
    to.name
  );
}

/**
 * We are going to use this two variable to restrict access to some path if the connected user is not authenticated
 */
const isNotAuthenticated = (to, _, next) => {
  const userIsConnected =
    isDefined(store.state.isConnected) &&
    store.state.isConnected &&
    tokenIsValid();
  if (isResettingPassword(to) || !userIsConnected) {
    next();
    return;
  }

  next({ name: "Dashboard" });
};

const isAuthenticated = (to, _, next) => {
  const userIsConnected =
    isDefined(store.state.isConnected) &&
    store.state.isConnected &&
    tokenIsValid();
  if (isResettingPassword(to) || userIsConnected) {
    next();
    return;
  }

  // store.commit(SET_DISCONNECTED)
  // dropVueStorage()

  store.dispatch("disconnect");
  window.location='/'
  // next({ name: "Login" });
};


const checkBillingAccess = (to, from, next) => {
  isAuthenticated(to, from, (authResult) => {
    if (authResult !== undefined) return; // Si redirection à cause de l'authentification, arrêter ici

    
    if(canAccessBilling( store.state.account.userInfos.role, store.state.account.userInfos.fonction, store.state.account.userInfos.speciality)){
      next();
      return;
    }

    vue.$toast.error("Vous n'avez pas les droits pour accéder à cette page");
  });
};


const checkProjectAccess = (to, from, next) => {
  isAuthenticated(to, from, (authResult) => {
    if (authResult !== undefined) return; // Si redirection à cause de l'authentification, arrêter ici
    
    if(canAccessProject(store.state.account.userInfos.fonction)){
      next();
      return;
    }

    vue.$toast.error("Vous n'avez pas les droits pour accéder à cette page");
  });
}

const routes = [
  {
    path: "/",
    redirect: `/login`,
  },
  // {
  //   path: "/:lang",
  //   component: {
  //     render(c) {
  //       return c("router-view");
  //     },
  //   },
  // },
  {
    path: "/lp-support-diane-thomas",
    name: "Registration",
    component: () =>
      import(
        /* webpackChunkName: "Registration" */ "../components/RegistTemp/Registration"
      ),
  },
  {
    path: "/reset-password/:token",
    name: "ResetPasswordVerifToken",
    component: () =>
      import(
        /* webpackChunkName: "ResetPasswordVerifToken" */ "../components/Account/ResetPasswordVerifToken"
      ),
    props: true,
    beforeEnter: isNotAuthenticated,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordTokenValid",
    component: () =>
      import(
        /* webpackChunkName: "ResetPasswordTokenValid" */ "../components/Account/ResetPasswordTokenValid"
      ),
    props: true,
    beforeEnter: isNotAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "UserLogIn" */ "../components/Account/UserLogIn"
      ),
    beforeEnter: isNotAuthenticated,
  },
  {
    path: "/2fa",
    name: "TwoFactor",
    component: () =>
      import(
        /* webpackChunkName: "TwoFactor" */ "../components/Account/TwoFactor"
      ),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
    import(/* webpackChunkName: "Profile" */ "../views/Profile"),
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "change_password",
        name: "ChangePassword",
        component: () =>
          import(
            /* webpackChunkName: "ChangePassword" */ "../components/modale/ChangePassword"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
    ],
  },
  {
    path: "/settings",
    name: "lpclient-settings",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/LpClientSettings"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/update_lpclient",
    name: "UpdateLPClient",
    component: () =>
      import(
        /* webpackChunkName: "AdminLPClients" */ "../components/LpClient/UpdateLpClient"
      ),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/dashboard-client",
    name: "dashboard-client",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "../views/customer_space/dashboard"
      ),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Dashboard"),
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "project/create",
        name: "AddProjectFromDashboard",
        component: () =>
          import(
            /* webpackChunkName: "AddProject" */ "../components/modale/AddProject"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        children: [
          {
            path: "step1",
            name: "AddProjectStepOneFromDashboard",
            component: () =>
              import(
                /* webpackChunkName: "AddProjectStepOne" */ "../components/modale/Project/AddProjectStepOne"
              ),
            beforeEnter: isAuthenticated,
            props: true,
          },
          {
            path: "step2",
            name: "AddProjectStepTwoFromDashboard",
            component: () =>
              import(
                /* webpackChunkName: "AddProjectStepTwo" */ "../components/modale/Project/AddProjectStepTwo"
              ),
            beforeEnter: isAuthenticated,
            props: true,
          },
          {
            path: "/step3/:slug",
            name: "AddProjectStepThreeFromDashboard",
            component: () =>
              import(
                /* webpackChunkName: "AddProjectStepThree" */ "../components/modale/Project/AddProjectStepThree"
              ),
            beforeEnter: isAuthenticated,
            props: true,
          },
        ],
      },
      {
        path: "todos/create",
        name: "Todo Create from Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "AddTodo" */ "../components/modale/AddTodo"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
      {
        path: "todos/view/:id",
        name: "Todo View from Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "ViewTodo" */ "../components/modale/ViewTodo"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
      {
        path: "todos/update/:id",
        name: "Todo Edit from Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "EditTodo" */ "../components/modale/EditTodo"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
      {
        path: "todos/delete/:id",
        name: "Todo Delete from Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "DeleteTodo" */ "../components/modale/DeleteTodo"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
      {
        path: "news/view/:id",
        name: "News View from Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "ViewNews" */ "../components/modale/ViewNews"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
    ],
  },
  {
    path: "/lpclients",
    name: "LPClients",
    component: () =>
      import(
        /* webpackChunkName: "LPClients" */ "../components/Partner/Dashboard/LPClients"
      ),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/dashboard/partner",
    name: "Dashboard Partner",
    component: () =>
      import(
        /* webpackChunkName: "DashboardPartner" */ "../views/DashboardPartner"
      ),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/coffee",
    name: "Coffee",
    component: () =>
      import(
        /* webpackChunkName: "CoffeeList" */ "../components/Coffee/List"
      ),
    beforeEnter: isAuthenticated,
  },

  //Project
  {
    path: "/project",
    name: "Project",
    component: () =>
      import(/* webpackChunkName: "Project" */ "../views/Project"),
    beforeEnter: checkProjectAccess,
    children: [
      {
        path: "create",
        name: "AddProject",
        component: () =>
          import(
            /* webpackChunkName: "AddProject" */ "../components/modale/AddProject"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        children: [
          {
            path: "step1",
            name: "AddProjectStepOne",
            component: () =>
              import(
                /* webpackChunkName: "AddProjectStepOne" */ "../components/modale/Project/AddProjectStepOne"
              ),
            beforeEnter: isAuthenticated,
            props: true,
          },
          {
            path: "step2",
            name: "AddProjectStepTwo",
            component: () =>
              import(
                /* webpackChunkName: "AddProjectStepTwo" */ "../components/modale/Project/AddProjectStepTwo"
              ),
            beforeEnter: isAuthenticated,
            props: true,
            meta: {
              keepAlive: false,
              reusable: false,
            },
          },
          {
            path: "step3/:slug",
            name: "AddProjectStepThree",
            component: () =>
              import(
                /* webpackChunkName: "AddProjectStepThree" */ "../components/modale/Project/AddProjectStepThree"
              ),
            beforeEnter: isAuthenticated,
            props: true,
            meta: {
              keepAlive: false,
              reusable: false,
            },
            children: [
              {
                path: "project/create/step3/:slug/send_invitation",
                name: "SendInvitationStep3",
                component: {
                  SendInvitation: SendInvitation,
                },
                beforeEnter: isAuthenticated,
                props: true,
                meta: {
                  keepAlive: false,
                  reusable: false,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/project/view/:id",
    name: "Project View",
    component: () =>
      import(
        /* webpackChunkName: "ProjectView" */ "../components/Project/ProjectView"
      ),
    props: true,
    meta: {
      keepAlive: false,
      reusable: false,
    },
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "send_invitation",
        name: "SendInvitation",
        component: {
          SendInvitation: SendInvitation,
        },
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "change_status",
        name: "ChangeProjectStatus",
        component: () =>
          import(
            /* webpackChunkName: "ChangeProjectStatus" */ "../components/modale/ChangeProjectStatus"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "add_partner_team",
        name: "AddPartnerTeamProject",
        component: () =>
          import(
            /* webpackChunkName: "AddPartnerTeamProject" */ "../components/modale/AddPartnerTeamProject"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "folder/create/:type",
        name: "AddFolderProject",
        component: () =>
          import(
            /* webpackChunkName: "AddFolder" */ "../components/modale/AddFolder"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "folder/delete",
        name: "DeleteFolderProject",
        component: () =>
          import(
            /* webpackChunkName: "DeleteFolder" */ "../components/modale/DeleteFolder"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "folder/edit",
        name: "EditFolderProject",
        component: () =>
          import(
            /* webpackChunkName: "EditFolder" */ "../components/modale/EditFolder"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/create",
        name: "AddFileProject",
        component: () =>
          import(
            /* webpackChunkName: "AddFile" */ "../components/modale/AddFile"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/delete",
        name: "DeleteFileProject",
        component: () =>
          import(
            /* webpackChunkName: "DeleteFile" */ "../components/modale/DeleteFile"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/share",
        name: "ShareFileContactProject",
        component: () =>
          import(
            /* webpackChunkName: "ShareFileContact" */ "@/components/modale/GED/ShareFileContact"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/copyUrl",
        name: "CopyFileLinkProject",
        component: () =>
          import(
            /* webpackChunkName: "ShareLink" */ "@/components/modale/AssignTask"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/assignTask",
        name: "AssignTaskProject",
        component: () =>
          import(
            /* webpackChunkName: "AssignTask" */ "@/components/modale/AssignTask"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "add/member",
        name: "AddMemberToTeam",
        component: () =>
          import(
            /* webpackChunkName: "AddMemberToTeam" */ "../components/modale/Project/AddMemberToTeam"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "remove/member",
        name: "RemoveMemberToTeam",
        component: () =>
          import(
            /* webpackChunkName: "RemoveMemberToTeam" */ "../components/modale/Project/RemoveMemberToTeam"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "folder/share",
        name: "ShareFolderProject",
        component: () =>
          import(
            /* webpackChunkName: "ShareFolder" */ "../components/modale/ShareFolder"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
    ],
  },

  //GED
  {
    path: "/ged",
    name: "GED",
    component: () => import(/* webpackChunkName: "GED" */ "../views/GED"),
    beforeEnter: isAuthenticated,
    meta: {  },
    children: [
      {
        // path:'g/ed/file/create',
        path: "file/create",
        name: "AddFile",
        component: () =>
          import(
            /* webpackChunkName: "AddFile" */ "../components/modale/AddFile"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/delete",
        name: "DeleteFile",
        component: () =>
          import(
            /* webpackChunkName: "DeleteFile" */ "../components/modale/DeleteFile"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/share",
        name: "ShareFileContact",
        component: () =>
          import(
            /* webpackChunkName: "ShareFileContact" */ "@/components/modale/GED/ShareFileContact"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/copy-url",
        name: "CopyFileLink",
        component: () =>
          import(
            /* webpackChunkName: "ShareLink" */ "@/components/modale/GED/ShareLink"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "file/assignTask",
        name: "AssignTask",
        component: () =>
          import(
            /* webpackChunkName: "AssignTask" */ "@/components/modale/AssignTask"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "folder/create/:type",
        name: "AddFolder",
        component: () =>
          import(
            /* webpackChunkName: "AddFolder" */ "../components/modale/AddFolder"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "folder/delete",
        name: "DeleteFolder",
        component: () =>
          import(
            /* webpackChunkName: "DeleteFolder" */ "../components/modale/DeleteFolder"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "folder/edit",
        name: "EditFolder",
        component: () =>
          import(
            /* webpackChunkName: "EditFolder" */ "../components/modale/EditFolder"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
      {
        path: "folder/share",
        name: "ShareFolder",
        component: () =>
          import(
            /* webpackChunkName: "ShareFolder" */ "../components/modale/ShareFolder"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
    ],
  },
  {
    path: "/ged/file/view/:slug",
    name: "open_file",
    component: () => import(/* webpackChunkName: "GED" */ "../views/GED"),
    beforeEnter: isAuthenticated,
    props: true,
    meta: {
      keepAlive: false,
      reusable: false,
    },
  },
  {
    path: "/ged/folder/view/:slug",
    name: "open_folder",
    component: () => import(/* webpackChunkName: "GED" */ "../views/GED"),
    beforeEnter: isAuthenticated,
    props: true,
    meta: {
      keepAlive: false,
      reusable: false,
    },
  },

  //Calendar
  {
    path: "/calendar",
    name: "Calendrier",
    component: () =>
      import(
        /* webpackChunkName: "Calendar" */ "../components/Calendar/Calendar"
      ),
    beforeEnter: isAuthenticated,
  },

  //Messenger
  {
    path: "/messenger",
    name: "Messagerie",
    component: () =>
      import(/* webpackChunkName: "Messenger" */ "../views/Messenger"),
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "conversation/create",
        name: "AddPersonalConversation",
        component: () =>
          import(
            /* webpackChunkName: "AddPersonalConversation" */ "@/components/modale/Messenger/AddPersonalConversation"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
      {
        path: "conversation/create",
        name: "AddProjectConversation",
        component: () =>
          import(
            /* webpackChunkName: "AddProjectConversation" */ "@/components/modale/Messenger/AddProjectConversation"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
      {
        path: "message/delete",
        name: "DeleteMessage",
        component: () =>
          import(
            /* webpackChunkName: "DeleteMessage" */ "@/components/modale/DeleteMessage"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
    ],
  },

  //Company
  {
    path: "/company",
    name: "Entreprise",
    component: () =>
      import(/* webpackChunkName: "Company" */ "../views/Company"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/company/create",
    name: "Company Create",
    component: () =>
      import(
        /* webpackChunkName: "CompanyCreate" */ "../components/Company/Create"
      ),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/company/update/:id/:redirect?",
    name: "Company Update",
    component: () =>
      import(
        /* webpackChunkName: "CompanyUpdate" */ "../components/Company/Update"
      ),
    props: true,
    meta: {
      keepAlive: false,
      reusable: false,
    },
    beforeEnter: isAuthenticated,
  },
  {
    path: "/company/view/:id",
    name: "Company View",
    meta: {
      keepAlive: false,
      reusable: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "CompanyView" */ "../components/Company/View"
      ),
    // props: true,
    props: ({ params }) => ({
      id: Number.parseInt(params.id, 10) || 0,
      name: String(params.name),
    }),
    beforeEnter: isAuthenticated,
  },

  //Contact
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../views/Contact"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/contact/create/:companyId?/:isLawyer?/:redirect?",
    name: "Contact Create",
    component: () =>
      import(
        /* webpackChunkName: "ContactCreate" */ "../components/Contact/Create"
      ),
    props: ({ params }) => ({
      type: String(params.type),
    }),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/contact/update/:id/:redirect?",
    name: "Contact Update",
    component: () =>
      import(
        /* webpackChunkName: "ContactUpdate" */ "../components/Contact/Update"
      ),
    props: true,
    beforeEnter: isAuthenticated,
    meta: {
      keepAlive: false,
      reusable: false,
    },
  },
  {
    path: "/contact/view/:id",
    name: "Contact View",
    component: () =>
      import(
        /* webpackChunkName: "ContactView" */ "../components/Contact/View"
      ),
    // props: true,
    props: ({ params }) => ({
      id: Number.parseInt(params.id, 10) || 0,
      email: String(params.email),
    }),
    meta: {
      keepAlive: false,
      reusable: false,
    },
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "contact/view/:id/interest/create",
        name: "AddInterest",
        component: () =>
          import(
            /* webpackChunkName: "AddInterest" */ "../components/modale/AddInterest"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
    ],
  },

  // Intern Directory
  {
    path: "/intern_directory",
    name: "Intern Directory",
    component: () =>
      import(
        /* webpackChunkName: "Directory" */ "../views/InternDirectory"
      ),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/intern_directory/view/:id",
    name: "Intern Directory View",
    component: () =>
      import(
        /* webpackChunkName: "DirectoryView" */ "../components/InternDirectory/View"
      ),

    props: true,
    beforeEnter: isAuthenticated,
  },
  {
    path: "/intern_directory/create",
    name: "Intern Directory Create",
    component: () =>
      import(
        /* webpackChunkName: "ContactCreate" */ "../components/modale/AddInternDirectory"
      ),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/intern_directory/update/:id",
    name: "Intern Directory Update",
    component: () =>
      import(
        /* webpackChunkName: "ContactCreate" */ "../components/modale/UpdateInternDirectory"
      ),

    props: ({ params }) => ({
      user: params.user,
    }),
    beforeEnter: isAuthenticated,
  },

  //Marketing
  {
    path: "/marketing",
    name: "Marketing",
    component: () =>
      import(/* webpackChunkName: "Marketing" */ "../views/Marketing"),
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "create",
        name: "AddCampaign",
        component: () =>
          import(
            /* webpackChunkName: "AddCampaign" */ "../components/modale/AddCampaign"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
    ],
  },
  {
    path: "/campagne/view/:id",
    name: "Marketing View",
    component: () =>
      import(
        /* webpackChunkName: "MarketingView" */ "../components/Marketing/MarketingView"
      ),
    props: true,
    meta: {
      keepAlive: false,
      reusable: false,
    },
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "campaign/delete",
        name: "DeleteMarketing",
        component: () =>
          import(
            /* webpackChunkName: "DeleteMarketing" */ "../components/modale/DeleteMarketing"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
      {
        path: "campaign/edit",
        name: "EditMarketing",
        component: () =>
          import(
            /* webpackChunkName: "EditMarketing" */ "../components/modale/EditMarketing"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
    ],
  },

  //News
  {
    path: "/news",
    name: "News",
    component: () => import(/* webpackChunkName: "News" */ "../views/News"),
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "create",
        name: "News Create",
        component: () =>
          import(
            /* webpackChunkName: "AddNews" */ "../components/modale/AddNews"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
      {
        path: "view/:id",
        name: "News View",
        component: () =>
          import(
            /* webpackChunkName: "ViewNews" */ "../components/modale/ViewNews"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
      {
        path: "delete/:id",
        name: "News Delete",
        component: () =>
          import(
            /* webpackChunkName: "DeleteNews" */ "../components/modale/DeleteNews"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
      {
        path: "update/:id",
        name: "News Update",
        component: () =>
          import(
            /* webpackChunkName: "EditNews" */ "../components/modale/EditNews"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
    ],
  },

  //Statistics
  {
    path: "/statistics",
    name: "Statistics",
    component: () =>
      import(/* webpackChunkName: "Statistics" */ "../views/Statistics"),
    beforeEnter: isAuthenticated,
  },

  //Todos
  {
    path: "/todos",
    name: "Todos",
    component: () =>
      import(/* webpackChunkName: "Todos" */ "../views/Todo"),
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "create",
        name: "Todo Create",
        component: () =>
          import(
            /* webpackChunkName: "AddTodo" */ "../components/modale/AddTodo"
          ),
        beforeEnter: isAuthenticated,
        props: true,
      },
      {
        path: "view/:id",
        name: "Todo View",
        component: () =>
          import(
            /* webpackChunkName: "ViewTodo" */ "../components/modale/ViewTodo"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
      {
        path: "update/:id",
        name: "Todo Edit",
        component: () =>
          import(
            /* webpackChunkName: "EditTodo" */ "../components/modale/EditTodo"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
      {
        path: "delete/:id",
        name: "Todo Delete",
        component: () =>
          import(
            /* webpackChunkName: "DeleteTodo" */ "../components/modale/DeleteTodo"
          ),
        beforeEnter: isAuthenticated,
        props: true,
        meta: {
          keepAlive: false,
          reusable: false,
        },
      },
    ],
  },

  //Mondays
  {
    path: "/mondays",
    name: "mondays",
    component: () =>
      import(/* webpackChunkName: "Billing" */ "../views/Mondays"),
    beforeEnter: isAuthenticated,
    meta: {  },
  },

  //Billing
  {
    path: "/billing",
    name: "Facturation",
    component: () =>
      import(/* webpackChunkName: "Billing" */ "../views/Billing"),
    beforeEnter: checkBillingAccess,
    meta: {  },
  },
  {
    path: "/billing/view/:id",
    name: "Billing View",
    component: () =>
      import(
        /* webpackChunkName: "BillingView" */ "../components/Billing/BillingView"
      ),
    props: true,
    meta: {
      keepAlive: false,
      reusable: false,
    },
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "update",
        name: "Billing Info Edit",
        component: () =>
          import(
            /* webpackChunkName: "EditBillingInfo" */ "../components/modale/EditBillingInfo"
          ),
        beforeEnter: isAuthenticated,
        meta: {  },
        props: true,
      },
    ],
  },
  {
    path: "/pdf-draft",
    name: "pdfView",
    component: () =>
      import(
        /* webpackChunkName: "indexPdf" */ "../components/Billing/templates/indexPdf"
      ),
    beforeEnter: isAuthenticated,
    meta: {  },
    props: true,
  },
  {
    path: "/billing/view/:id/invoice",
    name: "CreateInvoice",
    component: () =>
      import(
        /* webpackChunkName: "CreateInvoice" */ "../components/Billing/CreateInvoice"
      ),
    beforeEnter: isAuthenticated,
    props: true,
    meta: {
      keepAlive: false,
      reusable: false,
    },
  },

  //PastTimes
  {
    path: "/PastTimes",
    name: "Temps passés",
    component: () =>
      import(/* webpackChunkName: "PastTimes" */ "../views/PastTimes"),
    beforeEnter: isAuthenticated,
    meta: {  },
  },

  //PackageOverrun
  {
    path: "/PackageOverrun",
    name: "Dépassement forfait",
    component: () =>
      import(
        /* webpackChunkName: "PackageOverrun" */ "../views/PackageOverrun"
      ),
    beforeEnter: isAuthenticated,
    meta: {  },
  },

  // Cabinet
  {
    path: "/cabinet",
    name: "Cabinet",
    component: () =>
      import(/* webpackChunkName: "Office" */ "../views/Office"),
    beforeEnter: isAuthenticated,
  },

  //Lawyer
  {
    path: "/lawyer",
    name: "Avocats",
    component: () =>
      import(/* webpackChunkName: "Lawyer" */ "../views/Lawyer"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/lawyer/view/:id",
    name: "Lawyer View",
    component: () =>
      import(
        /* webpackChunkName: "ContactView" */ "../components/Lawyer/View"
      ),
    props: true,
    beforeEnter: isAuthenticated,
  },

  //File editor
  {
    path: "/Editor",
    name: "FileEditor",
    component: () => import("../components/GED/GEDFileEditor"),
    // beforeEnter: isAuthenticated,
    meta: {
      hideSideBar: true,
      hideTopBar: true,
    },
  },

  //Admin
  {
    path: "/admin/lpclients",
    name: "Admin LPClients",
    component: () =>
      import(
        /* webpackChunkName: "AdminLPClients" */ "../components/Admin/Lpclient/Lpclients-content"
      ),
    beforeEnter: isAuthenticated,
    meta: {
      hideSideBar: true,
      hideTopBar: true,
      showAdminTobar: true,
      role: "ROLE_SUPER_ADMIN",
    },
  },
  {
    path: "/admin/lpclients/add",
    name: "Admin LPClients Add",
    component: () =>
      import(
        /* webpackChunkName: "AdminLPClients" */ "../components/Admin/Lpclient/Lpclient-add"
      ),
    beforeEnter: isAuthenticated,
    meta: {
      hideSideBar: true,
      hideTopBar: true,
      showAdminTobar: true,
      role: "ROLE_SUPER_ADMIN",
    },
  },
  {
    path: "/admin/lpclients/update/:id",
    name: "Admin LPClients update",
    component: () =>
      import(
        /* webpackChunkName: "AdminLPClients" */ "../components/Admin/Lpclient/Lpclient-update"
      ),
    props: true,
    beforeEnter: isAuthenticated,
    meta: {
      keepAlive: false,
      reusable: false,
      hideSideBar: true,
      hideTopBar: true,
      showAdminTobar: true,
      role: "ROLE_SUPER_ADMIN",
    },
  },
  {
    path: "/admin/lpclient/view",
    name: "LPClient View",
    component: () =>
      import(
        /* webpackChunkName: "AdminLPClients" */ "../components/Admin/Lpclient/View"
      ),
    beforeEnter: isAuthenticated,
    meta: {
      keepAlive: false,
      reusable: false,
      hideSideBar: true,
      hideTopBar: true,
      showAdminTobar: true,
      role: "ROLE_SUPER_ADMIN",
    },
  },
  {
    path: "/admin/lpclient/registration",
    name: "LPClient User Registration",
    component: () =>
      import(
        /* webpackChunkName: "AdminLPClients" */ "../components/Admin/Lpclient/Lpclients-UserRegistration"
      ),
    beforeEnter: isAuthenticated,
    meta: {
      hideSideBar: true,
      hideTopBar: true,
      showAdminTobar: true,
      role: "ROLE_SUPER_ADMIN",
    },
  },
];

export default routes;
