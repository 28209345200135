<template>
  <div id='app' class='component-item dashboard'>

    <div v-if='isLogin'>
      <UserLogin />
    </div>

    <div v-else-if='isAuthentificationInProgress'>
      <TwoFactor />
    </div>

    <div v-else-if='isLPClientPage'>
      <PartnerLPClients />
    </div>
    <div v-else-if='ResetPasswordVerifToken'>
      <ResetPasswordVerifToken />
    </div>

    <div v-else-if='isResetPasswordTokenValid'>
      <ResetPasswordTokenValid />
    </div>

    <div v-else-if="isOnlyOfficePage" style="width: 100%; height:100%">
      <GEDFileEditor />
    </div>


    <div v-else-if='this.isConnectedStatus && !this.isLPClientPage' class='page-wrapper'>
      <Sidebar :collapsed='sidebarCollapsed' @toggle-collapsed='toggleSidebarCollapsed'
        v-if="!$route.meta.hideSideBar" />

      <div :class="[sidebarCollapsed === true ? collapsed : '', contentWrapper,]"
        :style="[$route.meta.hideSideBar && { paddingLeft: 0 }]">
        <div :class="{ 'main-content': !isOnlyOfficePage, 'h-full': isOnlyOfficePage }">

          <Topbar :collapsed='sidebarCollapsed' @toggle-collapsed='toggleSidebarCollapsed'
            v-if="!$route.meta.hideTopBar" />

          <AdminTopbar v-if="$route.meta.showAdminTobar" />
          <div :class="{ 'main-content-router': !isOnlyOfficePage, 'h-full': isOnlyOfficePage }">
            <!-- Your fixed-top navigation bar -->
            <router-view :key='$route.fullPath' />
          </div>
          <!-- Display the details of a task as an overlay (check the "Mondays" section on the dashboard or in the "Dossier client" section - "Gantt Display view") -->
          <div
            :class="(showMondayOverlay ? 'visible' : 'invisible') + ' fadeoverlay-div d-flex fixed-top justify-content-end w-100 h-100'">
            <div @click="hideMondayOverlay" class="col-0 col-sm-7 side-overlay-content"></div>
            <div class="h-100 col-12 col-sm-5 float-right p-4 shadow bg-white rounded mb-5">
              <MondayForeground />
            </div>
          </div>


          <div @click.stop="redirectToProject()"
            :class="(time !== '00:00:00' && displayOverlayTimer ? 'visible' : 'invisible') + ' cursor-pointer d-flex align-items-center justify-content-end shadow rounded custom-chrono py-4 m-4'">
            <div class="text-white fs-4 text-capitalize me-4">
              {{ projectTimer?.name?.toLowerCase() }}
            </div>
            <div class="mx-4 opacity-75">
              <font-awesome-icon icon="play" size="lg" style="color: white;" v-if="this.time === '00:00:00'" />
              <font-awesome-icon icon="pause" size="lg" style="color: white;" v-else />
            </div>
            <div class="text-white fs-3">
              {{ time }}
            </div>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="redirect-icon" color="white">
                <path
                  d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
              </svg></span>
          </div>
        </div>
        <Footer class='footerDashboard' v-if='isDashboard' />
      </div>

      <b-button v-if="showFlyingBtn && !isStarterPlan" @click="handleQuickAccessMessenger" class='flyingWidgetMessenger'
        variant='outline-secondary'>
        <svg style="cursor: pointer;" viewBox="0 0 650 512" height="30" width="30" fill="#ffff"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.7 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z" />
        </svg>
      </b-button>

      <b-modal id='modalMessenger' size='xl' hide-header hide-footer>
        <Messenger name='Messagerie'
          :class="{ 'quick-access': quickAccessMessenger, 'sidebar-access': !quickAccessMessenger }" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { isDefined } from './utils';
import { createSpinner } from '@syncfusion/ej2-popups';
import Vue from 'vue';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(faPlay, faPause);


export default {
  name: 'App',

  components: {
    Sidebar: () => import(/* webpackChunkName: 'Sidebar' */ './components/layout/Sidebar.vue'),
    AdminTopbar: () => import(/* webpackChunkName: 'AdminTopbar' */ './components/layout/AdminTopbar.vue'),
    Topbar: () => import(/* webpackChunkName: 'Topbar' */ './components/layout/Topbar.vue'),
    Footer: () => import(/* webpackChunkName: 'Footer' */ './components/layout/Footer.vue'),
    UserLogin: () => import(/* webpackChunkName: 'UserLogIn' */ './components/Account/UserLogIn.vue'),
    TwoFactor: () => import(/* webpackChunkName: 'TwoFactor' */ './components/Account/TwoFactor.vue'),
    PartnerLPClients: () => import(/* webpackChunkName: 'LPClients' */ './components/Partner/Dashboard/LPClients.vue'),
    Messenger: () => import(/* webpackChunkName: 'Messenger' */ './components/Messenger/Messenger.vue'),
    ResetPasswordVerifToken: () => import(/* webpackChunkName: "ResetPasswordVerifToken" */ './components/Account/ResetPasswordVerifToken.vue'),
    ResetPasswordTokenValid: () => import(/* webpackChunkName: "ResetPasswordTokenValid" */ './components/Account/ResetPasswordTokenValid.vue'),
    MondayForeground: () => import(/* webpackChunkName: "MondayForeground" */ './components/Mondays/MondayForeground.vue'),
    GEDFileEditor: () => import(/* webpackChunkName: "GEDFileEditor" */ './components/GED/GEDFileEditor.vue'),
    //ChatBotInterface:()=> import(/* webpackChunkName: "ChatInterface" */ './components/BotInterface/ChatInterface.vue')
    // StopWatch: () => import(/* webpackChunkName: "StopWatch" */ './components/Project/StopWatch.vue')
  },

  data() {
    return {
      sidebarCollapsed: true,
      contentWrapper: 'content-wrapper',
      collapsed: 'collapsed',
      quickAccessMessenger: false,
    }
  },

  mounted() {
    createSpinner({
      target: document.getElementById('app')
    });
    if (this.$store.state.isConnected) {
      this.initListener()
    }

    document.addEventListener('click', () => {
      this.closeAllMondayDropDownTabs()
    })
  },
  created() {
    // événement visant à déclencher les désabonnements Mercure lors de la sortie de l'app
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy() {
    this.closeListener()
    // suppression event prévu pour la désabo Mercure
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
    document.removeEventListener('click', this.closeAllMondayDropDownTabs())
  },
  computed: {
    ...mapGetters([
        'isStarterPlan'
      ]),
    ...mapState('stopWatchModule', {
      currentTime: 'currentTime',
      projectTimer: 'project',
      displayOverlayTimer: 'displayOverlayTimer'
    }),

    ...mapState({
      isConnected: 'isConnected',
      dataIsLoaded: 'dataIsLoaded',
      authenticationInProgress: 'authenticationInProgress',
    }),
    ...mapState('project', {
      project: 'project'
    }),
    ...mapState('uploadSys', ['numFiles']),
    ...mapState('monday', ['showMondayOverlay']),

    isDashboard() {
      return this.$route.name === 'Dashboard' || this.$route.name === 'Dashboard Partner'
    },
    showFlyingBtn() {
      return this.$route.name !== 'Messagerie' && !this.$route.meta.hideSideBar
    },

    time: {
      get() {
        return this.currentTime
      },
      set(value) {
        this.setCurrentTime(value)
      }
    },

    isConnectedStatus() {
      let bool = false
      if (isDefined(this.isConnected)) {
        bool = this.isConnected
      }
      return bool
    },

    isLPClientPage() {
      return this.$route.name === 'LPClients'
    },

    ResetPasswordVerifToken() {
      return this.$route.name === 'ResetPasswordVerifToken'
    },

    isResetPasswordTokenValid() {
      return this.$route.name === 'ResetPasswordTokenValid'
    },

    isAuthentificationInProgress() {
      return this.$route.name === 'TwoFactor'
    },

    isLogin() {
      return this.$route.name === 'Login'
    },

    isOnlyOfficePage() {
      return this.$route.name === 'FileEditor'
    }
  },

  methods: {
    // ...mapActions('ged', {
    //   listenFolderUpdates: 'listenFolderUpdates',
    //   listenFileUpdates: 'listenFileUpdates',
    //   listenFileSharing: 'listenFileSharing',
    //   listenTodoTaskUpdates: 'listenTodoTaskUpdates'
    // }),

    // ...mapActions('messenger', {
    //   listenMessage: 'listenMessage',
    //   listenConversation: 'listenConversation'
    // }),

    // ...mapActions('project', {
    //   listenFolderUpdatesSafe: 'listenFolderUpdatesSafe',
    //   listenFileUpdatesSafe: 'listenFileUpdatesSafe'
    // }),

    ...mapMutations('stopWatchModule', {
      setCurrentTime: 'setCurrentTime',
      setDisplayOverlayTimer: 'setDisplayOverlayTimer'
    }),
    ...mapActions('account', {
      listenForNewNotification: 'listenForNewNotification',
      stopListenForNewNotification: 'stopListenForNewNotification',
    }),
    ...mapActions('ged', {
      listenFolderUpdates: 'listenFolderUpdates',
      listenFileUpdates: 'listenFileUpdates',
      listenFileSharing: 'listenFileSharing',
      listenTodoTaskUpdates: 'listenTodoTaskUpdates',
      stopListenFolderUpdates: 'stopListenFolderUpdates',
      stopListenFileUpdates: 'stopListenFileUpdates',
      stopListenFileSharing: 'stopListenFileSharing',
      stopListenTodoTaskUpdates: 'stopListenTodoTaskUpdates'
    }),

    ...mapActions('messenger', {
      listenMessage: 'listenMessage',
      listenConversation: 'listenConversation',
      stopListenMessage: 'stopListenMessage',
      stopListenConversation: 'stopListenConversation'
    }),

    ...mapActions('project', {
      listenFolderUpdatesSafe: 'listenFolderUpdatesSafe',
      listenFileUpdatesSafe: 'listenFileUpdatesSafe',
      stopListenFolderUpdatesSafe: 'stopListenFolderUpdatesSafe',
      stopListenFileUpdatesSafe: 'stopListenFileUpdatesSafe'

    }),

    ...mapMutations('monday', {
      closeAllMondayDropdownTabs: 'closeAllDropdowns'
    }),

    ...mapActions('monday', ['hideMondayDetails']),

    closeAllMondayDropDownTabs() {
      this.closeAllMondayDropdownTabs()
    },

    initListener() {
      // Account's data for Mercure
      this.listenForNewNotification()

      // Messenger's data for Mercure
      this.listenConversation()
      this.listenMessage()

      //GED's data for Mercure
      this.listenFolderUpdates()
      this.listenFileSharing()
      this.listenFileUpdates()
      this.listenTodoTaskUpdates()

      //Project's data for Mercure
      this.listenFolderUpdatesSafe()
      this.listenFileUpdatesSafe()

      //Partie Monday à réaliser

    },

    closeListener() {
      // Account's data for Mercure
      this.stopListenForNewNotification()

      // Messenger's data for Mercure
      this.stopListenConversation()
      this.stopListenMessage()
      //Project's data for Mercure
      this.stopListenFolderUpdatesSafe()
      this.stopListenFileUpdatesSafe()
      //GED's data for Mercure
      this.stopListenFolderUpdates()
      this.stopListenFileSharing()
      this.stopListenFileUpdates()
      this.stopListenTodoTaskUpdates()

      //Partie Monday à réaliser
    },

    beforeWindowUnload() {
      this.closeListener()
    },

    toggleSidebarCollapsed() {
      this.sidebarCollapsed = !this.sidebarCollapsed
    },
    handleQuickAccessMessenger() {
      this.quickAccessMessenger = true;
      this.$bvModal.show('modalMessenger');
    },
    handleSidebarMessenger() {
      this.quickAccessMessenger = false;
      this.$bvModal.show('modalMessenger');
    },
    hideMondayOverlay() {
      this.hideMondayDetails()
    },
    redirectToProject() {
      if (this.project) {
        this.setDisplayOverlayTimer(false)
        this.$router.push({
          name: 'Project View',
          params: { id: this.project.slug, title: this.project.name }
        }).then(() => window.scrollTo(0, 0));
      } else {
        console.log("Project slug is currently undefined")
      }
    },
  },
}
</script>

<style scoped>
/**
 * IMPORT CSS SYNCFUSION
 * @type {AxiosInstance}
 */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-vue-grids/styles/material.css';
/**
 * IMPORT CSS SYNCFUSION
 * @type {AxiosInstance}
 */

.e-spinner-pane {
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2000;
}

.main-content-router {
  z-index: 998;
}

.overlay-div {
  z-index: 999;
}

.side-overlay-content {
  background-color: rgba(0, 0, 0, 0.7);
}

.custom-chrono {
  width: fit-content;
  position: fixed;
  top: 0;
  right: 190px;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  margin-top: 25px !important;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 0 30px 0 30px;
  z-index: 999;
  background-color: #5233ff;
}

.redirect-icon {
  width: 12px;
  margin-left: 6px;
  margin-top: -5px;
  color: white;
  fill: white;
  white-space: normal;
}

.h-full {
  height: 100%;
}
</style>