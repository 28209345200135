export function getDuration(startDate, startTime, endDate, endTime) {
    if ((startTime == endTime && startDate != endDate) || (startTime != endTime && startDate == endDate)) {
        endDate = new Date(endDate)
        startDate = new Date(startDate)
        var duration = new Date(endDate.getYear(), endDate.getMonth(), endDate.getDay(), endTime[0] + endTime[1], endTime[3] + endTime[4]) - new Date(startDate.getYear(), startDate.getMonth(), startDate.getDay(), startTime[0] + startTime[1], startTime[3] + startTime[4])
        return convertMsToHM(duration)
    }
    return null
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function convertMsToHM(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    minutes = minutes % 60;
    hours = hours % 24;
    let days = Math.floor((milliseconds / (1000 * 60 * 60 * 24)) % 7);
    let weeks = Math.floor(milliseconds / (1000 * 60 * 60 * 24 * 7));
    var reponse = `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
    if (days >= 1) {
        reponse = days.toString() + " jours et " + reponse
    }
    if (weeks >= 1) {
        reponse = weeks.toString() + " semaines et " + reponse
    }
    return reponse
}

export function reverseDate(str) {
    var date = str.split('/')
    var dd = parseInt(date[0])
    var mm = parseInt(date[1])
    var yyyy = parseInt(date[2])

    return new Date(yyyy, mm - 1, dd)

}

//objDate is yyyy-month-dd hh:mm:ss
export function getTimeFromObjDate(date) {

    return date
}
export function getDateFromObjDate(date) {
    return date
}

export function transformDate(date) {
    var temp = new Date(date)
    var newDate = temp.getFullYear() + '-' + indexingTime(temp.getMonth() + 1) + '-' + indexingTime(temp.getDate()) + ' ' + indexingTime(temp.getHours()) + ':' + indexingTime(temp.getMinutes()) + ':' + indexingTime(temp.getSeconds())
    return newDate
}

function indexingTime(time) {
    return time < 10 ? '0' + time.toString() : time.toString()
}

export function convertMinutesToHHMM(minutes) {

    var m = Math.round(minutes % 60);

    var h = Math.round((minutes - m) / 60);

    return (h < 10 && h > 0 || h < 10 ? "0" : "") + h.toString() + " : " + (m < 10 && m > 0 || m < 10 ? "0" : "") + m.toString() + " ";
}


export function ddMMYYYYFromDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
}

export function getDateObjectFromDDMMYYY(date) {
    if(date == null){
        return null
    }
    // Extracting month, day, and year from the date string
    // Extracting day, month, and year from the date string
    const [day, month, year] = date.split('/');

    // Creating the Date object with the modified format
    return new Date(`${month}/${day}/${year}`);

}


export function dateByMinusSign(date) {
    // Extracting month, day, and year from the date string
    // Extracting day, month, and year from the date string
    const [day, month, year] = date.split('/');
  
    // Creating the Date object with the modified format
    return `${month}/${day}/${year}`;

}

export function dateObjFromMinusSign(date) {
    // Extracting month, day, and year from the date string
    // Extracting day, month, and year from the date string
    const [day, month, year] = date.split('-');

    // Creating the Date object with the modified format
    return new Date(`${month}/${day}/${year}`);

}

export function toMinus(date) {
    // Extracting month, day, and year from the date string
    // Extracting day, month, and year from the date string
    const [day, month, year] = date.split('/');

    // Creating the Date object with the modified format
    return (`${day}-${month}-${year}`);

}