import axiosRequest from "@/AuthenticatedRoute";

const state={
    hashMap: {},
    //hashMapFolderFile:new Map()//FoldeSlug[File]
    //Map Object Doesnt work
    uploadingState:false,
    numFiles:0,
    currentPromiseIndex:0,
    numPromises:1
    
}

const getters={
}

const mutations={
    addObjInMap(state,{name,slug}){
        state.hashMap[name]=slug
    },
    cleanHash(state){
        state.hashMap={}
    },
    stopLoading(state){
        state.uploadingState=false
        state.numPromises=1
        state.currentPromiseIndex=0,
        state.numFiles=0
        state.hashMap={}
    },
    setUploadingState(state,val){
        if(state.numPromises-1!==state.currentPromiseIndex){
            return
        }
        state.uploadingState=val
        state.numPromises=1
        state.currentPromiseIndex=0
    },
    addNumFilesToTotal(state,val){
        state.numFiles+=val
    },
    addCurrentPromiseIndex(state,val){
        state.currentPromiseIndex+=val
    },
    addNumPromises(state,val){
        state.numPromises+=val
    }

}

const actions={
    cleanHash({commit}){
        commit('cleanHash')
    },
    uploadFolder({state,commit,dispatch}, {name, type,parent,fullPath,projectSlug=null}) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            if(name) {
                formData.append('name', name)
            }

            let folderType
            if(parent){
                formData.append('parent', parent)  
            }

        
            folderType = type
            
            formData.append('type', folderType)

            axiosRequest({
                url: !projectSlug?'/ged/folder/':'/project/sub/ged/'+projectSlug,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(data && !state.hashMap[fullPath]){

                    commit('addObjInMap',{slug:data.slug,name:fullPath})
                    if(!projectSlug){
                        dispatch('ged/creatingFolderFromUpload',data,{root:true}) 
                    }else{
                        dispatch('project/createFolderFromUpload',data,{root:true})
                    }
                    
                    
                }
                
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line no-unused-vars
    uploadFile({dispatch,commit}, data) {
        // if(isEmptyObject(state.currentFolder))
        //     return null
        commit('addNumPromises',1)
        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('name', data.name);
            formData.append('title', data.title);
            formData.append('reference', data.file);

            if(data.description && data.description.trim() !== '') {
                formData.append('description', data.description);
            }

            if(data.keywords !== undefined) {
                formData.append('keywords', data.keywords.join(','))
            }

            var projectSlug=data.projectSlug?data.projectSlug:null
            axiosRequest({
                method: 'POST',
                url: !data.projectSlug?'/ged/file/create/'+data.currentFolder:'/project/sub/ged/file/'+data.projectSlug+'/create/'+data.currentFolder,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                // if(isDefined(data)){
                //     commit(ADD_FILES, {files: buildFiles([data])})
                // }
                if(!projectSlug){
                    dispatch('ged/creatingFileFromUpload',data,{root:true})
                }
                else{
                    dispatch('project/createFileFromUpload',data,{root:true})
                }
                commit('addCurrentPromiseIndex',1)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    // eslint-disable-next-line no-unused-vars
    uploadChunk({commit},{chunk,fileName,slug,chunkIndex}){
        commit('addNumPromises',1)
        return new Promise((resolve,reject)=>{
            const formData = new FormData();
            formData.append('chunk', chunk);
            formData.append('fileName',fileName)
            formData.append('chunkIndex',chunkIndex)
            axiosRequest({
                method:'POST',
                url:'/ged/file/uploadChunk/'+slug,
                data:formData,
                headers: { "Content-Type": "multipart/form-data" }
            }).then(response=>{
                const data=response.data.data
                commit('addCurrentPromiseIndex',1)
                resolve(data)
            }).catch(error=>{
                reject(error)
            })

        })
    },

    // eslint-disable-next-line no-unused-vars
    assembleFile({commit,dispatch},{name,title,currentFolder,chunkNames,type,projectSlug}){
        commit('addNumPromises',1)
        return new Promise((resolve,reject)=>{
            const formData = new FormData();
            formData.append('name', name);
            formData.append('title',title)
            
            formData.append('chunkNames',chunkNames)
            formData.append('type',type)
            if(projectSlug){
                formData.append('projectSlug',projectSlug)
            }
            axiosRequest({
                method:'POST',
                url:'/ged/file/assembleFile/'+currentFolder,
                data:formData,
                headers: { "Content-Type": "multipart/form-data" }
            }).then(response=>{
                const data=response.data.data //file created
                
                if(!projectSlug){
                    dispatch('ged/creatingFileFromUpload',data,{root:true})
                }
                else{
                    dispatch('project/createFileFromUpload',data,{root:true})
                }
                
                commit('addCurrentPromiseIndex',1)
                commit('setUploadingState',false)
                resolve(data)
            }).catch(error=>{
                reject(error)
            })

        })
    },

    addNumFilesToTotal({commit},val){
        commit('addNumFilesToTotal',val)
    }
}

export default{
    namespaced:true,
    state,getters,mutations,actions
}