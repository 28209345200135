import Vue from 'vue';
import App from './App.vue';
import router from './routing/router';
import store from './store';
// import i18n from './i18n';
import { ComboBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
Vue.use(ComboBoxPlugin);


import msal from 'vue-msal';
Vue.use(msal, {
  auth: {
    clientId: 'd6d9e658-99cb-41c4-83c8-8c20974b47a4',
    redirectUri:process.env.VUE_APP_MS,
    autoRefreshToken:true,
    postLogoutRedirectUri:process.env.VUE_APP_MS,
    
    navigateToLoginRequestUrl:false,
    requireAuthOnInitialize:false,
    validateAuthority:false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
});

import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
//import { required } from "vee-validate/dist/rules";
extend("matchPassword", {
  validate: (value, { other }) => value === other,
  params: ["other"],
  message: "Les mots de passe ne correspondent pas",
});

const toastOptions = {}
Vue.use(Toast, toastOptions)

import { registerLicense } from '@syncfusion/ej2-base';
//registerLicense('Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9iS3xbd0VgWXtbeHJRTg==;Mgo+DSMBPh8sVXJ0S0J+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xSdEdrWH9dcHBQTmdUWQ==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhUX5ec3RRQ2hVUEw=;ODMxMzEzQDMyMzAyZTM0MmUzMFE5Sk9La2UwUDM4S1Bycm04V3h5NWs4QTBqQW9rVmsyVGwyc0gweVlleHc9;ODMxMzE0QDMyMzAyZTM0MmUzMEgvUWtZWmlsZ00ycmJkdnlvTlgyU2h4MmhzdEJPV3RBbkcza2poNGFFVEk9;NRAiBiAaIQQuGjN/V0Z+WE9EaFxKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdERhW3dfcHdVQmVVV0R+;ODMxMzE2QDMyMzAyZTM0MmUzMGh0NGxFOVhMTklhZjJmbnlxcElrb09JV1N6QVRwd0c2Z3ZvN1NWSVMvblk9;ODMxMzE3QDMyMzAyZTM0MmUzMFdFWVVsK2NCWUE0ditrZ0Z1S3dzekQvKzlPa0tCVXRPNlNwZUN0Myt1cDA9;Mgo+DSMBMAY9C3t2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhUX5ec3RRQGBfUEw=;ODMxMzE5QDMyMzAyZTM0MmUzMFVQNUR4THRmcWtXVVRyRDRPNjl0RmdjblFGWGFpSHJPS1JBaWtQcGl6cUU9;ODMxMzIwQDMyMzAyZTM0MmUzMG9QN3ZHT0xZRThZRjFFVkgzMFdXbXc5aTJSc1pQQU5XMmF1bW1CaGxpVTQ9;ODMxMzIxQDMyMzAyZTM0MmUzMGh0NGxFOVhMTklhZjJmbnlxcElrb09JV1N6QVRwd0c2Z3ZvN1NWSVMvblk9');
registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZjUH9acHNVRGVUVUd1XA==');


import { loadCldr,L10n,setCulture, } from '@syncfusion/ej2-base';

loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/fr/ca-gregorian.json'),
    require('cldr-data/main/fr/numbers.json'),
    require('cldr-data/main/fr/timeZoneNames.json')
);
import * as numberingSystems from './assets/culture/numberingSystems.json';
import * as gregorian from './assets/culture/fr/ca-gregorian.json';
import * as numbers from './assets/culture/fr/numbers.json';
import * as timeZoneNames from './assets/culture/fr/timeZoneNames.json';
import * as lang from './assets/js/language'



setCulture('fr');
L10n.load(lang.langFr)
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

import { enableRipple } from '@syncfusion/ej2-base';
enableRipple(true);

import '@/assets/css/styles.scss';

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  // i18n,
  filters: {
    unescape: v => unescape(v)
  },
  render: h => h(App)
}).$mount('#app')
