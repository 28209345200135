import {
    ADD_NEWS,
    ADD_NEWS_SCRAP,
    ADD_NEWS_COMPANIES,
    ADD_NEWS_KEYWORD,
    READ_NEWS,
    REMOVE_NEWS,
    RESET_NEWS_KEYWORD,
    DELETE_NEWS,
    UPDATE_NEWS,
    ADD_KEYWORDS_NEWS
} from "@/store/mutations-types";
import axiosRequest from "@/AuthenticatedRoute";
import {buildFormData, getObjectKey, isDefined, dateFormatted} from "@/utils";

const namespaced = true;

const state = {
    news: [],
    newsItem: [],
    newsLoaded: false,
    newsScrap: [],
    newsScrapLoaded: false,
    newsCompanies: [],
    newsKeyword: [],
    keywords: []
}

const mutations = {
    [ADD_NEWS](state, {news}){
        for(const itemNew of news){
            if(state.news.filter(item => item.id === itemNew.id).length === 0){
                state.news.push(itemNew)
            }
        }

        state.newsLoaded = true
    },

    [ADD_NEWS_SCRAP](state, {news}){
        for(const itemNew of news){
            if(state.newsScrap.filter(item => item.id === itemNew.id).length === 0){
                state.newsScrap.push(itemNew)
            }
        }

        state.newsScrapLoaded = true
    },

    [ADD_NEWS_COMPANIES](state, {news}){
        for(const itemNew of news){
            if(state.newsCompanies.filter(item => item.id === itemNew.id).length === 0){
                state.newsCompanies.push(itemNew)
            }
        }
    },

    [ADD_NEWS_KEYWORD](state, {news}){
        state.newsKeyword = []
        for(const itemNew of news){
            if(state.newsKeyword.filter(item => item.id === itemNew.id).length === 0){
                state.newsKeyword.push(itemNew)
            }
        }
    },

    [READ_NEWS](state, {data}){
        state.newsItem = data
    },

    [REMOVE_NEWS](state){
        state.newsItem = []
    },

    [RESET_NEWS_KEYWORD](state){
        state.newsKeyword = []
    },

    [DELETE_NEWS](state, {id})  {
        state.news = state.news.filter(item => item.id !== id)
    },

    [UPDATE_NEWS](state, {itemNew}){
        state.news = state.news.map(item => item.id === itemNew.id ? itemNew : item)
    },

    [ADD_KEYWORDS_NEWS](state, {keywords}) {
        for(const keyword of keywords) {
            if(state.keywords.filter(item => item.id === keyword.id).length === 0) {
                state.keywords.push(keyword)
            }
        }
    },
}

const actions = {
    //News
    async createNews({rootState, commit, dispatch}, data) {
        const method = "POST"

        let internalData = data
        internalData['lpClient'] = rootState.account.userInfos.lpClient.id

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/news/create',
                data: buildFormData(internalData, method, true)
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_NEWS, {news: buildNew(data, true)})
                }

                dispatch('loadEmployeeNews')

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadScrapNews({commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/news/scrape',
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_NEWS_SCRAP, {news: buildNews(data)})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadScrapCompaniesNews({commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/news/scrape-companies-news',
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_NEWS_COMPANIES, {news: buildNews(data)})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadScrapNewsByKeywords({commit}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/news/scrape-by-keyword?q='+data,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_NEWS_KEYWORD, {news: buildNews(data)})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    resetNewsByKeyword({commit}) {
      commit(RESET_NEWS_KEYWORD)
    },

    async loadEmployeeNews({ commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/news/employee-news',
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_NEWS, {news: buildNews(data)})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async getNews({commit}, {id}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/news/view/'+id,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(READ_NEWS, {data: data})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    removeNews({commit}) {
        commit(REMOVE_NEWS)
    },

    async deleteNews({commit}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/news/delete/'+data.id,
            }).then(response => {
                commit(DELETE_NEWS, {id: parseInt(data.id)})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateNews({commit}, {id, form}) {

        const method = "PUT"
        let internalData = form

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/news/update/'+id,
                data: buildFormData(internalData, method, true)
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(UPDATE_NEWS, {itemNew: buildNew(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadKeywords({commit}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'GET',
                url: '/tag/list',
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    commit(ADD_KEYWORDS_NEWS, {keywords: buildKeywords(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced,
    state,
    actions,
    mutations,
}

function buildNews(data){
    let result = []

    for(const item of data){
        result.push(buildNew(item))
    }

    return result
}

function buildNew(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        result = {
            "id": getObjectKey(item, 'id'),
            "title": getObjectKey(item, 'title'),
            "summary": getObjectKey(item, 'summary'),
            "url": "https://" + getObjectKey(item,'url'),
            "note": getObjectKey(item, 'note'),
            "tags": getObjectKey(item, 'tags'),
            "created_at": dateFormatted(getObjectKey(item, 'created_at')),
            "is_private": getObjectKey(item, 'is_private'),
            "is_public": getObjectKey(item, 'is_public'),
        }

        if(isDefined(item.lp_client) && item.lp_client !== null){
            result['lp_client']= {
                'id': getObjectKey(item.lp_client, 'id'),
                'name': getObjectKey(item.lp_client, 'name')
            }
        }else{
            result['lp_client'] = null
        }

        if(isDefined(item.company) && item.company !== null){
            result['company']= {
                'id': getObjectKey(item.company, 'id'),
                'name': getObjectKey(item.company, 'name')
            }
        }else{
            result['company'] = null
        }

        if(isDefined(item.created_by) && item.created_by !== null){
            result['created_by']= {
                'id': getObjectKey(item.created_by, 'id'),
                'firstname': getObjectKey(item.created_by, 'firstname'),
                'lastname': getObjectKey(item.created_by, 'lastname'),
                'email': getObjectKey(item.created_by, 'email'),
                'is_employee': getObjectKey(item.created_by, 'is_employee'),
            }
        }else{
            result['created_by'] = null
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildKeywords(data){
    let result = []

    for(const item of data){
        result.push(buildKeyword(item))
    }

    return result
}

function buildKeyword(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        result = {
            "id": getObjectKey(item, 'id'),
            "label": getObjectKey(item, 'label'),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}