import axiosRequest from "@/AuthenticatedRoute";

import { isDefined } from "@/utils";
const namespaced = true;

const state = {
  socials: [],
};

const mutations = {
  setSocials(state, socials) {
    state.socials = socials;
  },
  removeSocial(state, id) {
    state.socials = state.socials.filter((social) => social.id !== id);
  },
};

const actions = {
  async createSocial(_, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        url: "/user/lp_client/createSocial",
        data: data,
      })
        .then((response) => {

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getSocials({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/user/lp_client/" + id + "/socials",
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit("setSocials", data);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async deleteSocial({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "DELETE",
        url: "/user/lp_client/" + id + "/deleteSocial",
      })
        .then((response) => {
          commit("removeSocial", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
  async updateSocial(_, data) {
    const id = data.id;
    const body = {
      name: data.name,
      link: data.link,
    };
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "PUT",
        url: "/user/lp_client/" + id + "/updateSocial",
        data: body,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
