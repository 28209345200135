import {
    ADD_OFFICE,
} from "@/store/mutations-types";
import axiosRequest from "@/AuthenticatedRoute";
import { getObjectKey, isDefined } from "@/utils";

const namespaced = true;

const state = {
    offices: [],
    officesLoaded: false
}

const mutations = {
    [ADD_OFFICE](state, {offices}){
        for(const itemoffice of offices){
            if(state.offices.filter(item => item.id === itemoffice.id).length === 0){
                state.offices.push(itemoffice)
            }
        }

        state.officesLoaded = true
    },
}

const actions = {
    //Offices
    async loadOffices({commit}){
        //if(state.companies.length === 0) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/cabinet/list',
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_OFFICE, {offices: buildOffices(data)})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        //}
    },
}

export default {
    namespaced,
    state,
    actions,
    mutations,
}

function buildOffices(data){
    let result = []

    for(const item of data){
        result.push(buildOffice(item))
    }

    return result
}

function buildOffice(item, returnArray = false){
    let result = null

    if(isDefined(item.company)){
        const conditionType = item.is_customer ? 'Client' : ( item.is_cabinet ? 'Cabinet' : ( item.is_prospect ? 'Prospect' :( item.is_supplier ? 'Fournisseur': '')))

        result = {
            "id": getObjectKey(item.company, 'id'),
            "name": getObjectKey(item.company, 'name'),
            "email": getObjectKey(item.company, 'email'),
            "address": getObjectKey(item.company, 'address'),
            "additional_address": getObjectKey(item.company, 'additional_address'),
            "city": getObjectKey(item.company, 'city'),
            "country": getObjectKey(item.company,'country'),
            "postcode": getObjectKey(item.company, 'postcode'),
            "office_phone": getObjectKey(item.company, 'office_phone', true),
            "fax_number": getObjectKey(item.company, 'fax_number', true),
            "website": getObjectKey(item.company, 'website'),
            "siret": getObjectKey(item.company, 'siret'),
            "vat": getObjectKey(item.company, 'vat'),
            "nace_code": getObjectKey(item.company, 'nace_code'),
            "avatar": getObjectKey(item.company, 'avatar'),
            "is_prospect": getObjectKey(item.company, 'is_prospect'),
            "is_customer": getObjectKey(item.company, 'is_customer'),
            "is_supplier": getObjectKey(item.company, 'is_supplier'),
            "is_cabinet": getObjectKey(item.company, 'is_cabinet'),
            "created_at": getObjectKey(item, 'created_at'),
            "type": conditionType,
            "size": getObjectKey(item, 'size'),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}
