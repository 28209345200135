import axiosRequest from "@/AuthenticatedRoute";
import {getToDosView,getImprovisedTaskView} from '@/utils/utilsTasks'
// import {dateFormattedFromDate} from '@/utils'

const state={
    validTimeTasks:[],
    teamsChosen:[],
    filteredValidTimeTasks:[]
}

const getters={
    getAllTasksViewByUser(state){
        
        var tasks=state.validTimeTasks
        let usersTasks={}

        for(const element of tasks){
            var tmp=element
            if(typeof tmp.startDate === 'string'){
                //In case of boomerang
                tmp.startDate=new Date()
            }
   
            if(element.responsible in usersTasks){
                var tempArr=usersTasks[tmp.responsible]
                tempArr.push(tmp)
                usersTasks[tmp.responsible]=tempArr
            }
            else{
                usersTasks[tmp.responsible]=[tmp]
            }
        }
        return usersTasks
    },
}

const mutations={

    setNull(state){
        state.validTimeTasks=[]
        state.teamsChosen=[]
        state.filteredValidTimeTasks=[]
    },
    setFilteredAllTasks(state,{rootState}){
        var email
        if(rootState!=null || rootState!=undefined){
            email=rootState.account.userInfos.email
        }
        else{
            email=this.state['account']['userInfos']['email']
        }
        var temp=this.getters['validTimeModule/getAllTasksViewByUser']
        var tempArr=[]
        for (const [key,] of Object.entries(temp)) {
             tempArr.push({[key]:temp[key]})
        }
        //the improvisedTasksView is an object where the key is the name of responsible for the tasks and the value is the tasks themselves.
        //so the array would be a [{responsibleName:[task1,task2]},{responsibleName2:[task1,task2]]
        var tempFiltered=tempArr.filter(element=>{
          return email === Object.values(element)[0][0].responsibleEmail
              || state.teamsChosen.includes(Object.values(element)[0][0].teamName);
        })
        state.filteredValidTimeTasks=tempFiltered
        
    },
    setValidTimeTasks(state,tasks){

        var todosUser=getToDosView(tasks[0])
        var todosTeam=getToDosView(tasks[1])
        //CHANGE
        //var improvisedTasks=getImprovisedTaskView(tasks[2].improvisedToDoTasks)
        var improvisedTasks=getImprovisedTaskView(tasks[3].improvisedToDoTasks)
        var mergedArrs=[...improvisedTasks,...todosUser,...todosTeam,]
    
        state.validTimeTasks=mergedArrs.filter(element=>{
            return element.duration!=='00 : 00' && element.status==='Validé' && ((element.isTodo && element.workload>0 && element.progress>0)||(!element.isTodo))//element.timeValidation==='Validé'
        })
        
    },
    setChosenTeams(state,teams){
        state.teamsChosen=teams
    },
    addInChosenTeams(state,team){
        var teamName=team.value
        if(state.teamsChosen.filter(element=>element==teamName).length<1){
            state.teamsChosen.push(teamName)
        }
    },
    deleteInChosenTeams(state,team){
        var teamName=team.text
        state.teamsChosen=state.teamsChosen.filter(element=>element!=teamName)
    },

    // changeTaskInValidTime(state,task){
    //     state.validTimeTasks=state.validTimeTasks.map(element=>{
    //         if(element.isTodo===task.isTodo){
    //             if(Object.prototype.hasOwnProperty.call(element, 'idTask') && Object.prototype.hasOwnProperty.call(element, 'idTask') && element.idTask===task.idTask){
    //                 return task
    //             }
    //             if(Object.prototype.hasOwnProperty.call(element, 'id') && Object.prototype.hasOwnProperty.call(element, 'id') && element.id===task.id){
    //                 task['startDate']=task.scheduledDate
    //                 return task
    //             }
    //             return element
    //         }
    //         return element
    //     })
    // }
    
    addToDoTaskInState(state,task){ 
        if(state.validTimeTasks.filter(element=>{
            return task.isTodo && element.id===task.id
        }).length===0){
            state.validTimeTasks.push(task)
        }
    },

    addImprovisedTaskInState(state,task){
        if(state.validTimeTasks.filter(element=>{
            return !task.isTodo && element.idTask===task.idTask
        }).length===0){
            state.validTimeTasks.push(task)
        }
    },

    updateImprovisedTaskInState(state,task){
        state.validTimeTasks=state.validTimeTasks.map(element=>{
            if(!task.isTodo && element.idTask===task.idTask){
                return task
            }
            return element
        })
    },
    deleteImprovisedTaskInState(state,task){
        state.validTimeTasks=state.validTimeTasks.filter(element=>{
            return !(!task.isTodo && element.idTask === task.idTask);
        })
    },
    updateToDoTaskInState(state,task){
        state.validTimeTasks=state.validTimeTasks.map(element=>{
            if(task.isTodo && element.id===task.id){
                return task
            }
            return element
        })
    },
    deleteToDoTaskInState(state,task){
        state.validTimeTasks=state.validTimeTasks.filter(element=>{
            return !(task.isTodo && element.id === task.id);
        })
    },
}

const actions={
    setValidationTimeNull({commit}){
        commit('setNull')
    },
    updateImprovisedTask({commit,rootState},task){
        commit('updateImprovisedTaskInState',task)
        commit('setFilteredAllTasks',{rootState})
    },
    addImprovisedTask({commit,rootState},task){
        commit('addImprovisedTaskInState',task)
        commit('setFilteredAllTasks',{rootState})
    },
    deleteImprovisedTask({commit,rootState},task){
        commit('deleteImprovisedTaskInState',task)
        commit('setFilteredAllTasks',{rootState})
    },
    addToDoTask({commit,rootState},task){
        commit('addToDoTaskInState',task)
        commit('setFilteredAllTasks',{rootState})
    },
    updateToDoTask({commit,rootState},task){
        commit('addToDoTaskInState',task)
        //we are trying to add a task in state before update. After reloading the page we won't get this task in store
        //because of the condition to don't have it with 00:00 time,so if we do update it we would get updated in response API,but store doesnt have this task.
        commit('updateToDoTaskInState',task)
        commit('setFilteredAllTasks',{rootState})
    },
    deleteToDoTask({commit,rootState},task){
        commit('deleteToDoTaskInState',task)
        commit('setFilteredAllTasks',{rootState})
    },
    addTeamAction({rootState,commit},teamObj){
        commit('addInChosenTeams',teamObj)
        commit('setFilteredAllTasks',{rootState})
    },
    deleteTeamAction({rootState,commit},teamObj){
        commit('deleteInChosenTeams',teamObj)
        commit('setFilteredAllTasks',{rootState})
    },
    // validTaskInValidTime({rootState,commit},task){
    //     commit('changeTaskInValidTime',task)
    //     commit('setFilteredAllTasks',{rootState})
    // },
    
    fetchCombinedTasks({rootState, commit}){
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'GET',
                // url:'/todotask/list-all/'+rootState.project.current_project_slug,
                url:'/todotask/list-all/'+rootState.project.project.slug,
                headers:{
                    Authorization:'Bearer '+rootState.account.userInfos.token
                }
            }).then(reponse=>{
                const data=reponse.data.data
                commit('setValidTimeTasks',data)
                commit('setFilteredAllTasks',{rootState})
                resolve(reponse)
            }).catch(error=>{
                reject(error)
            })
        })
    }
}

const namespaced=true

export default{
    namespaced,
    state,getters,actions,mutations
}
